import React from "react";
import { Link } from "react-router-dom";

export const DownloadPage = (props) => {

  const {isMobile} = props;
  
  // style={{'fontSize':'6vh','color':'white',}}
  let content
  if (isMobile){
    content =
    <div style={{'display':'flex','flex-direction':'column','height':'100vh','background': 'linear-gradient(to top, #e6b980 0%, #eacda3 100%)'}}>
      <div style={{'display':'flex', 'flex-direction':'column','padding-top':'5rem','padding-left':'2rem','align-self':'center'}}>
        <h1 className="intro-h1">Coming Soon</h1>
      </div>
      <div style={{'maxHeight': '100vh', 'padding-left':'3.5rem'}}>
        <img src="../img//Mobile-1.png" height="400" alt=""></img>
      </div>
      <div style={{'display':'flex', 'flex-direction':'column','padding-top':'7rem', 'padding-left':'2rem', 'padding-right':'2rem'}}>
      <Link to="/" className="btn btn-custom btn-lg" href="">Back Home</Link>

      </div>
    </div>
  }else{
    content = 
      <div style={{'display':'flex','flex-direction':'row','height':'100vh','background': 'linear-gradient(to top, #e6b980 0%, #eacda3 100%)'}}>
        <div style={{'padding-left':'10vw','align-self':'center','width':'50%'}}>
          <h1 className="intro-h1">Coming Soon</h1>
          <Link to="/" className="btn btn-custom btn-lg" href="">Back Home</Link>
        </div>
        <div style={{'maxHeight': '100vh','align-self':'center'}}>
          <img src="../img//Mobile-1.png" alt=""></img>
        </div>
      </div>
  }
  return (
    content
  );
};