import React, { useState, useEffect } from "react";
import { Header } from "./components/header";
import {OHeader} from "./components/bars/Oheader.jsx"
import { OHeader1 } from "./components/bars/Oheader1.jsx";
import {OGallery} from "./components/bars/Ogallery.jsx"
import {Demo} from "./components/bars/Demo.jsx"
import {OSlideshow} from "./components/bars/OSlideshow.jsx";
import { OContact } from "./components/bars/OContact";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { DownloadPage } from "./components/DownloadPage";
import Faq from "./components/Faq.jsx"
import { TCPage } from "./components/TCPage.jsx";
import NavigationWrapper from './helper/NavigationWrapper.jsx';
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Slideshow } from "./components/slideshow";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { OHeader2 } from "./components/bars/Oheader2";
import { OHeader3 } from "./components/bars/Oheader3";


export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });
  
  const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      setLandingPageData(JsonData);
      setIsMobile(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
    }, []);

    return (
        <Router>
          <NavigationWrapper />
          <Routes>
            <Route path="/" element={
              <>
                <Header data={landingPageData.Header} isMobile={isMobile} />
                <About data={landingPageData.About} />
                <Slideshow isMobile={isMobile} />
                <Services data={landingPageData.Services} isMobile={isMobile}/>
                {isMobile ? null : <Gallery data={landingPageData.Gallery} />}
                <Contact data={landingPageData.Contact} />
              </>
            } />
            <Route path="/download" element={<DownloadPage data={landingPageData.DownloadPage} isMobile={isMobile}/>} />
            <Route path="/demo" element={<Demo isMobile={isMobile}/>} />
            <Route path="/faq" element={<Faq data={landingPageData.Faq} isMobile={isMobile}/>} />
            <Route path="/TermsAndConditions" element={<TCPage data={landingPageData.TCPage} isMobile={isMobile}/>} />
            <Route path="/forbars" element={
              <>
                <OHeader data={landingPageData.OHeader} isMobile={isMobile} />
                {/* <About data={landingPageData.About} /> */}
                {/* <Slideshow isMobile={isMobile} /> */}
                {/* <Services data={landingPageData.Services} isMobile={isMobile}/> */}
                {isMobile ? null : <OHeader1 data={landingPageData.Gallery1} />}
                <OHeader3 data={landingPageData.About} isMobile={isMobile} />
                <OSlideshow isMobile={isMobile} />
                <OHeader2 data={landingPageData.About} isMobile={isMobile} />
                <OContact data={landingPageData.Contact} />
              </>
            } />
          </Routes>
        </Router>
      );
    };
    
    export default App;