import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import scrollToSection from './scrollHelper';

const CustomLink = ({ to, sectionId, children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [targetSection, setTargetSection] = useState(null);

  useEffect(() => {
    if (targetSection) {
      // Add a delay before scrolling to the section
      const timeoutId = setTimeout(() => {
        scrollToSection(targetSection);
        setTargetSection(null); // Reset after scrolling
      }, 200); // Adjust the delay as needed (300ms)

      // Clean up timeout if component unmounts or effect runs again
      return () => clearTimeout(timeoutId);
    }
  }, [location, targetSection]);

  const handleClick = (e) => {
    if (location.pathname === to) {
      if (sectionId) {
        e.preventDefault(); // Prevent link default action
        scrollToSection(sectionId);
      }
    } else {
      e.preventDefault();
      setTargetSection(sectionId); // Set the target section
      navigate(to);
      window.scrollTo(0, 0); // Scroll to the top of the new page
    }
  };

  return (
    <Link to={to} onClick={handleClick}>
      {children}
    </Link>
  );
};

export default CustomLink;
