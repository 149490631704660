import React from "react";
import CustomLink from '../../helper/CustomLink';
import { Link } from "react-router-dom";

export const OHeader = (props) => {

  return (
    <header id="header">
      <div className="ointro">
        <div className="ointro-bg"></div> {/* Background layer for the blur */}
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1 className={props.isMobile ? 'intro-mobile-h1': 'ointro-h1'}>
                  {"Liquidity"}
                  <span></span>
                </h1>
                <h2 style={{ color: "#C89037" }}>
  {"Frictionless service at no cost."}
</h2>
                <p>{"At Liquidity, we understand the importance of a smooth night out for our customers and seamless ops for our partner bars. Our app will bring a new era of efficiency to bars everywhere."}</p>
                <div className="btn btn-custom btn-lg">
                    <Link to="/demo" href="">Request a Demo</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};