import React, { useEffect } from "react";
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import summary1 from '../app-pics/summary1.png';
import summary2 from '../app-pics/summary2.png';
import summary3 from '../app-pics/summary3.png';
import summary4 from '../app-pics/summary4.png';

export const OHeader2 = (props) => {
  useEffect(() => {
    new Splide('.splide', {
      type: 'loop',
      perPage: 1,
      perMove: 1,
      pagination: true,
      breakpoints: {
        768: {
          perPage: 1,
        },
        1024: {
          perPage: 1,
        },
      },
    }).mount();
  }, []);

  return (
    <div id="about" className="about-section" style={{ backgroundColor: '#f5f5f5' }}> {/* Light grey background */}
      <div className="about-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xs-12 col-md-6 d-flex justify-content-center align-items-center">
              <div className="splide" style={{ width: '100%', maxWidth: '690px', height: '500px', position: 'relative', borderRadius: '20px', overflow: 'hidden', backgroundColor: 'rgb(200, 144, 55)' }}> {/* Added borderRadius, overflow, and backgroundColor */}
                <div className="splide__track" style={{ height: '100%' }}>
                  <ul className="splide__list">
                    <li className="splide__slide" style={{ height: '100%' }}>
                      <img 
                        src={summary1} 
                        alt="Image 1" 
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'contain', 
                          borderRadius: '20px' 
                        }} 
                      />
                    </li>
                    <li className="splide__slide" style={{ height: '100%' }}>
                      <img 
                        src={summary2} 
                        alt="Image 2" 
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'contain', 
                          borderRadius: '20px' 
                        }} 
                      />
                    </li>
                    <li className="splide__slide" style={{ height: '100%' }}>
                      <img 
                        src={summary3} 
                        alt="Image 3" 
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'contain', 
                          borderRadius: '20px' 
                        }} 
                      />
                    </li>
                    <li className="splide__slide" style={{ height: '100%' }}>
                      <img 
                        src={summary4} 
                        alt="Image 4" 
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'contain', 
                          borderRadius: '20px' 
                        }} 
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 d-flex justify-content-center align-items-center" > 
              <div className="about-text" style={{marginTop: '12.5rem'}}>
                <h2>Experience the Liquidity Difference</h2>
                <p>Join the ranks of downtown Toronto's most successful bars and revolutionize your operations with Liquidity. Our dedicated team is committed to providing unparalleled support and ongoing innovation to help you thrive in today's dynamic market. Discover the power of Liquidity and elevate your bar to new heights of success and efficiency.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
