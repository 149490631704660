import Faqs from './faqfile/Faqs'

import './Faq.css'

const faqsList = [
  {
    id: 0,
    questionText: 'How does Liquidity work?',
    answerText:
      'Liquidity is a mobile ordering platform that allows customers to browse the menu, place orders, and make payments directly from their smartphones. Simply download the Liquidity app, select your desired items, and submit your order for seamless service.',
  },
  {
    id: 1,
    questionText: 'Is Liquidity available at all bars in downtown Toronto?',
    answerText:
      "Liquidity is currently available at select bars in downtown Toronto. We're continually expanding our network of partner establishments, so be sure to check the app for updates on new locations.",
  },
  {
    id: 2,
    questionText:
      'Do I need to create an account to use Liquidity?',
    answerText:
      'Yes, creating an account is necessary to use Liquidity. This allows us to provide personalized recommendations, track your order history, and facilitate secure payments.',
  },
  {
    id: 3,
    questionText: 'Can I customize my order through Liquidity?',
    answerText:
      "Absolutely! Liquidity allows you to customize your order according to your preferences, whether it's modifying ingredients, specifying cooking instructions, or adding special requests.",
  },
  {
    id: 4,
    questionText: 'How do I pay for my order on Liquidity?',
    answerText:
      "Liquidity accepts various payment methods, including credit/debit cards and mobile payment options like Apple Pay. Simply select your preferred payment method during checkout.",
  },
  {
    id: 5,
    questionText: 'Is there a minimum order requirement on Liquidity?',
    answerText:
      "Generally, there are no minimum order requirements on standard bar orders through Liquidity, however certain events/promotions may carry a minimum spend requirement. Minimum order requirements may vary depending on the specific bar/event’s policies. You'll find details about minimum order amounts, if applicable, displayed within the Liquidity app.",
  },
  {
    id: 6,
    questionText: 'Can I track the status of my order through Liquidity?',
    answerText:
      "Yes, Liquidity provides real-time updates on the status of your order, including confirmation of receipt, preparation, and estimated time for pickup or delivery.",
  },
  {
    id: 7,
    questionText: 'How long does it take for my order to be ready?',
    answerText:
      "The preparation time for orders may vary depending on factors such as order volume and complexity. If you would like to check the exact status of your order, requesting an update from service staff will provide you with a recent and accurate estimate of when your order will be completed.",
  },
  {
    id: 8,
    questionText: 'Can I place orders for external delivery through Liquidity?',
    answerText:
      "No, Liquidity does not offer alcohol delivery services. However, users are able to place orders for pick-up on the way to their establishment of choice. Simply select the option and time that suits you best in the app, and the bar will do their best to accommodate your arrival time. ",
  },
  {
    id: 9,
    questionText: 'Is there a service fee for orders placed through Liquidity?',
    answerText:
      "Yes, Liquidity charges a small service fee on orders placed through the platform. Service fees may vary based on factors such as the bar you are ordering from, order size, and order complexity. Details regarding service fees are provided within the Liquidity app.",
  },
  {
    id: 10,
    questionText: 'What should I do if I encounter an issue with my order?',
    answerText:
      "If you experience any issues with your order, such as missing items, incorrect charges, or health and safety issues please contact our customer support team immediately. We're here to assist you and ensure your satisfaction.",
  },
  {
    id: 11,
    questionText: 'Can I earn rewards or loyalty points through Liquidity?',
    answerText:
      "Yes, Liquidity allows you to earn Liquidity Points through applicable purchases on the app. Please note that not all purchases will award Liquidity Points, and the amount in which you will receive Liquidity Points may vary based on factors like the bar you are ordering from, and the price of your order. Check the app for details on available rewards and how to earn them with your purchases.",
  },
  {
    id: 12,
    questionText: 'Is my personal information secure when using Liquidity?',
    answerText:
      "Yes, Liquidity takes data security and privacy seriously. We utilize industry-standard encryption protocols to safeguard your personal information and ensure secure transactions.",
  },
  {
    id: 13,
    questionText: "Can I cancel or modify my order after it's been placed?",
    answerText:
      "Once an order is submitted through Liquidity, modifications are not possible. However, you can always cancel your order regardless of its stage of preparation. Please note that you will not be eligible for a refund if the bar has already accepted your order.",
  },
  {
    id: 14,
    questionText: 'How can I provide feedback or suggestions for Liquidity?',
    answerText:
      "We welcome feedback and suggestions from our customers to help us improve and enhance the Liquidity experience. You can submit feedback by contacting our customer support team with your comments and ideas. Your input is invaluable to us!",
  },
]

const ofaqsList = [
    {
      id: 0,
      questionText: 'What is Liquidity and how can it benefit my bar?',
      answerText: 'Liquidity is a mobile ordering platform designed to streamline operations and enhance customer experiences at bars. By implementing Liquidity, your bar can offer seamless mobile ordering, reduce wait times, and improve overall efficiency.',
    },
    {
      id: 1,
      questionText: 'How does Liquidity integrate with my existing POS system?',
      answerText: 'Liquidity seamlessly integrates with a variety of POS systems commonly used in bars. Our technical team will work closely with you to ensure smooth integration and synchronization of data between Liquidity and your POS system.',
    },
    {
      id: 2,
      questionText: 'Does Liquidity require any additional hardware or equipment?',
      answerText: 'Yes, if you choose to integrate Liquidity into your existing POS system, you will need a dedicated Liquidity iPad and QR scanner to ensure the effective delivery of pickup orders to your customers. However, if you would like to install Liquidity separately, you will require additional hardware such as iPads, and a Liquidity-exclusive receipt printer. This hardware will be provided by us at no extra cost to our services as part of our agreement with your establishment. We are also happy to provide all necessary installment services to help you get started on your Liquidity journey.',
    },
    {
      id: 3,
      questionText: "Can I customize the menu and pricing on Liquidity to match my bar's offerings?",
      answerText: "Yes, Liquidity allows you to customize your bar's menu and pricing according to your specific offerings and pricing strategy. Our user-friendly administrator terminal makes it easy to manage and update your menu in real-time.",
    },
    {
      id: 4,
      questionText: 'How does Liquidity handle payments and transactions?',
      answerText: 'Liquidity facilitates secure payments through various methods, including credit/debit cards and mobile payment options like Apple Pay. Transactions are processed securely through our platform in compliance with PCI standards.',
    },
    {
      id: 5,
      questionText: 'What measures does Liquidity take to ensure data security and privacy?',
      answerText: 'Liquidity prioritizes data security and privacy. We employ industry-standard encryption protocols to safeguard customer data and ensure secure transactions at all times.',
    },
    {
      id: 6,
      questionText: 'Can I track and analyze customer data and order trends with Liquidity?',
      answerText: 'Yes, Liquidity provides comprehensive analytics and reporting tools that allow you to track customer data, order trends, and sales performance. Gain valuable insights to optimize your operations and enhance profitability.',
    },
    {
      id: 7,
      questionText: 'Does Liquidity offer customer support for bar owners and staff?',
      answerText: 'Yes, Liquidity provides dedicated customer support for bar owners and staff. Our support team is available to assist with any questions, issues, or technical challenges you may encounter.',
    },
    {
      id: 8,
      questionText: 'Can I set up promotions, discounts, or loyalty programs through Liquidity?',
      answerText: 'Absolutely! Liquidity offers flexible promotion and discount features, as well as the ability to create and manage loyalty programs to reward repeat customers and encourage customer loyalty.',
    },
    {
      id: 9,
      questionText: 'How does Liquidity handle order fulfillment and pickup/delivery logistics?',
      answerText: 'Liquidity streamlines order fulfillment with intuitive order management tools that allow you to track both real time and previous orders. Liquidity’s bar-order delivery system is designed to integrate seamlessly into the workflow of your bar, ensuring minimal disruptions to service efficiency, and maximized customer convenience. Finally, Liquidity’s table-service system is designed to follow the exact same procedure of normal table service at your establishment, ensuring no confusion from servers, bartenders, or customers.',
    },
    {
      id: 10,
      questionText: 'Is Liquidity customizable to suit the branding and aesthetics of my bar?',
      answerText: 'Yes, Liquidity can be customized to reflect the branding and aesthetics of your bar. From logo placement to color schemes, we\'ll work with you to ensure that Liquidity seamlessly integrates with your brand identity.',
    },
    {
      id: 11,
      questionText: 'Does Liquidity provide marketing and promotional support for partner bars?',
      answerText: 'Yes, Liquidity offers marketing and promotional support for partner bars through various channels, including social media, in-app promotions, and event campaigns. Leverage our platform to reach new customers and drive business growth.',
    },
    {
      id: 12,
      questionText: 'What is the cost structure for using Liquidity at my bar?',
      answerText: 'From the perspective of your establishment, Liquidity is completely free! This is because we make money on service fees charged to customers for every order they place on our platform, ensuring that we don’t generate revenues without making money for you first.',
    },
    {
      id: 13,
      questionText: "How can I get started with Liquidity and integrate it into my bar's operations?",
      answerText: "Getting started with Liquidity is easy! Simply reach out to our team to schedule a demo and discuss your specific requirements. We'll guide you through the setup process and provide ongoing support to ensure a seamless integration into your bar's operations.",
    },
  ];
  
const Faq = () => <Faqs faqsList={faqsList} ofaqsList={ofaqsList} />

export default Faq
