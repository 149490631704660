import React from "react";

export const OHeader3 = (props) => {
    return (
      <div id="about" className="about-section">
         <div className="about-bg">
        <div className="container">
          <div className="row align-items-center"> {/* Add align-items-center here */}
           
            <div className="col-xs-12 col-md-6" style={{marginTop: '40px'}}>
              <div className="about-text">
                <h2>Enhancing Table Service by Giving Customers a Choice</h2>
                <p>Whether they’re on a date, engaging in business drinks, having an important conversation, or are simply in a rush, Liquidity allows them to order without a server. 



                </p>
                <p>This means your staff can focus more on giving quality service to customers that need it, while achieving a higher order volume than ever. 

 
                </p>
                <p>With Liquidity, interactions are better, service is more efficient, and tips are higher.
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <img src="img/Mobile.png" className="img-responsive" alt="Toronto Skyline" /> {/* Add rounded-img class here */}
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
