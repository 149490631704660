// NavigationWrapper.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Navigation } from '../components/navigation.jsx'; // adjust the path if necessary

const NavigationWrapper = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/download' && <Navigation />}
    </>
  );
};

export default NavigationWrapper;
