import React from "react";
import { Link } from "react-router-dom";


export const Services = (props) => {

    const {isMobile} = props;
    
    // style={{'fontSize':'6vh','color':'white',}}
    let content
    if (isMobile){
      content =
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Download Liquidity Today</h2>
          <div className="download-img-phone">
            <div className="col-md-8">
          <p>
          Do yourself a favour and try Liquidity, we promise you’ll love it.
          </p>
          </div>
          <div className="service-btn" style={{'align-self':'center'}}>
  <a href="https://apps.apple.com/ca/app/liquidity-canada/id6450681529" className="btn btn-custom btn-lg" target="_blank" rel="noopener noreferrer">Download</a>
</div>

          </div>
        </div>
       
      </div>
    </div>
    }else{
        content = 
<div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Download Liquidity Today</h2>
          <div className="download-img">
            <div className="col-md-8">
          <p>
          Do yourself a favour and try Liquidity, we promise you’ll love it.
          </p>
          </div>
          <div className="col-md-4 text-bg" style={{'align-self':'center'}}>
  <a href="https://apps.apple.com/ca/app/liquidity-canada/id6450681529" className="btn btn-custom btn-lg" target="_blank" rel="noopener noreferrer">Download</a>
</div>

          </div>
        </div>
       
      </div>
    </div>
    }
    return (
        content
      );
};