import React from "react";
import { Link } from "react-router-dom";

export const TCPage = (props) => {

    const {isMobile} = props;
  
    // style={{'fontSize':'6vh','color':'white',}}
    let content
    if (isMobile){
      content =
      <div style={{'display':'flex','flex-direction':'column','height':'100vh','background': 'linear-gradient(to top, #e6b980 0%, #eacda3 100%)'}}>
        <div style={{'display':'flex', 'flex-direction':'column','padding-top':'5rem','padding-left':'2rem','align-self':'center'}}>
          <h1 className="intro-h1">Coming Soon</h1>
        </div>
        <div style={{'maxHeight': '100vh', 'padding-left':'3.5rem'}}>
          <img src="../img//Mobile-1.png" height="400" alt=""></img>
        </div>
        <div style={{'display':'flex', 'flex-direction':'column','padding-top':'7rem', 'padding-left':'2rem', 'padding-right':'2rem'}}>
        <Link to="/" className="btn btn-custom btn-lg" href="">Back Home</Link>
  
        </div>
      </div>
    }else{
      content = 
        <div style={{'display':'flex','flex-direction':'column'}}>
          <div style={{'padding-left':'10vw','align-self':'center','width':'50%'}}>
            <h1 className="tc-header">Terms and Conditions</h1>
          </div>
          <div className="tc-paragraph">

<p><strong>Liquidity terms and conditions for Customers order through Liquidity APP</strong></p>

<p>ORDERING time is between 11 am till 11 pm on any given days, other than days when as per respective State Excise laws, sale of alcohol is prohibited. All alcoholic products must be consumed in the outlet itself. Alcoholic products cannot be taken out from the premises under any circumstances. Food and alcohol products ordered must be consumed as scheduled by you while placing the order. You may place an order for up to 72 hours in advance, within the stipulated ordering time. You can order a maximum of 10 units each of any available brand in the categories of Single Malt Whisky, Premium Whisky, Regular Whisky, Vodka, Cognac, Rum, Gin, Tequila, Brandy, Liqueurs, Champagne, Wine (per unit is = 30 ml), Low Alcoholic Beverages (per unit is 500 ml), Beers (per unit is 330 ml) per transaction.</p>

<p>In the Unlikely event of Participating outlet not being able to provide you with your selection of product, the outlet will alternatively provide you with a similar or higher product at the price which has been already paid by you.</p>

<p>At the time when the order is successfully placed, you will receive a confirmation via SMS and email, with the invoice, which you need to present at the outlet upon your arrival. Upon presentation of the same, your order will be served.</p>

<p>At the time of placing the order, please indicate the number of guests, to enable the outlet to reserve the table for you.</p>

<p>Liquidity charges a transaction fee of Rs 25 on any order below Rs 1500 and Rs 50 for orders valued Rs 1500 and above.</p>

<p>Pubs, Bars, and Restaurants have Dress codes and Gate Policies. You are requested to kindly adhere to the same upon your choice of the outlet.</p>

<p><strong>Cancellations</strong></p>

<p>You may cancel your order for free within 15 minutes of placing the order. If you cancel the order anytime after 15 minutes to within one hour of your scheduled time, 25% of the order value is deducted. In case you cancel your order less than one hour of your scheduled time, 50% of the order value is deducted. No refund allowed if the order is canceled within less than 30 minutes of your scheduled time. Refund amount will be credited to your liquidity wallet within 3 working days. The credited amount will be after applied deductions and transaction fees cannot be refunded.</p>

<p><strong>ReSchedule</strong></p>

<p>You may reschedule your order once for a fee of Rs 50 as per the below terms:</p>

<ol>
  <li>Rescheduling can be done till 4 (four) hours before your scheduled arrival.</li>
  <li>You may reschedule the time and/or the date once within the time frame of 72 hours thereon.</li>
  <li>Rescheduling is allowed only once, and rescheduled orders cannot be canceled or modified.</li>
</ol>

<p>To ensure that the reserved table is provided to you on your arrival, you are requested to arrive at your scheduled time, failing which the outlet may not be able to immediately provide you with the table, and under these circumstances, only the next available table will be provided to you.</p>

<p>For your table reservation, you are allowed a grace time of 15 minutes between your scheduled arrival time and actual arrival time.</p>

<p>Failure to arrive within the grace time would cancel your order without a refund. The grace time but cannot be beyond the closing time of the outlet or the last alcohol service time, whichever is earlier.</p>

<p>Outlet might, at its sole discretion, extend you the courtesy of some more grace time for your arrival beyond the scheduled time, upon receipt of your specific request by them.</p>

<p><strong>Order Modifications</strong></p>

<p>Once the order has been placed, the order cannot be modified with regard to change of product and/or the quantity. You may cancel or reschedule the order as per the cancellation & rescheduling policy.</p>

<p><strong>Refund</strong></p>

<p>In the event the outlet fails to provide you with your selected product, and you are unwilling to accept the alternative being offered, we will refund your amount to your Liquidity wallet for the product which was unavailable. The refund claim must be lodged within 24 hours of your visit to the participating outlet.</p>

<p>To claim such a refund, please log in to your Liquidity account and select HELP in the options.</p>
        <div className="back-home">
          <Link to="/" className="btn btn-custom btn-lg" href="">Back Home</Link>
          </div>
          </div>

        </div>
    }
    return (
      content
    );
};