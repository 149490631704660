import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

const initialState = {
  barName: "",
  barAddress: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  preferredDate: "",
  notes: "",
};

export const Demo = (props) => {
  const [state, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { barName, barAddress, contactName, contactEmail, contactPhone, preferredDate, notes } = state;

    const emailBody = `
      Bar Name: ${barName}
      Bar Address: ${barAddress}
      Contact Name: ${contactName}
      Contact Email: ${contactEmail}
      Contact Phone Number: ${contactPhone}
      Preferred Demo Date: ${preferredDate}
      Notes: ${notes}
    `;

    const encodedBody = encodeURIComponent(emailBody);
    const user = "info";
    const domain = "@liquiditybars.com";
    const link = `mailto:${user}${domain}?subject=Demo%20Booking%20Request%20from%20${encodeURIComponent(barName)}&body=${encodedBody}`;

    window.location.href = link;
  };

  const { isMobile } = props;

  let content;
  if (isMobile) {
    content = (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            background: "linear-gradient(to top, #e6b980 0%, #eacda3 100%)",
            padding: "0",
          }}
        >
          <div id="contact" style={{ width: "100%", padding: "2rem" }}>
            <div className="container" style={{ width: "100%" }}>
              <div className="col-md-12" style={{ width: "100%" }}>
                <div style={{ maxWidth: "100%", flexDirection: "column", width: "100%" }}>
                  <div className="section-title">
                    <h2>Explore Liquidity For Your Bar</h2>
                    <p style={{ textAlign: "left", marginTop: "-10px" }}>
                    Reach out to us to begin your journey with Liquidity by filling out the form below, and we will get back to you as quickly as possible.
                    </p>
                  </div>
                  <form name="sentMessage" validate="true" onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <div className="form-group">
                      <input
                        type="text"
                        id="barName"
                        name="barName"
                        className="form-control"
                        placeholder="Bar Name"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        id="barAddress"
                        name="barAddress"
                        className="form-control"
                        placeholder="Bar Address"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        id="contactName"
                        name="contactName"
                        className="form-control"
                        placeholder="Contact Name"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="contactEmail"
                        name="contactEmail"
                        className="form-control"
                        placeholder="Contact Email"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        id="contactPhone"
                        name="contactPhone"
                        className="form-control"
                        placeholder="Contact Phone Number"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        id="preferredDate"
                        name="preferredDate"
                        className="form-control"
                        placeholder="Preferred Demo Date (MM/DD/YYYY)"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <textarea
                        name="notes"
                        id="notes"
                        className="form-control"
                        rows="4"
                        placeholder="Please include any other notes we should consider when preparing for our meeting."
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      ></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                    <div id="success"></div>
                    <button type="submit" className="btn btn-custom btn-lg" style={{ display: "block", width: "100%", marginBottom: "10px" }}>
                      Send Message
                    </button>
                    <Link to="/" className="btn btn-custom btn-lg" style={{ display: "block", width: "100%" }}>
                      Back Home
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  } else {
    content = (
      <div
        style={{
          background: "linear-gradient(to top, #e6b980 0%, #eacda3 100%)",
        }}
      >
        <div
          style={{
            paddingLeft: isMobile ? "0" : "20vw",
            paddingRight: isMobile ? "0" : "20vw",
            paddingTop: "2vw",
            paddingBottom: "2vw",
            alignSelf: "center",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div id="contact" style={{ width: "100%" }}>
            <div className="container" style={{ width: "100%" }}>
              <div className="col-md-12" style={{ width: "100%" }}>
                <div style={{ maxWidth: "100%", flexDirection: "column", width: "100%" }}>
                  <div className="section-title">
                    <h2>Explore Liquidity For Your Bar</h2>
                    <p style={{ textAlign: "left", marginTop: "-10px" }}>
                    Reach out to us to begin your journey with Liquidity by filling out the form below, and we will get back to you as quickly as possible.
                    </p>
                  </div>
                  <form name="sentMessage" validate="true" onSubmit={handleSubmit} style={{ width: "100%" }}>
                    <div className="form-group" style={{ marginBottom: "2.5rem" }}>
                      <input
                        type="text"
                        id="barName"
                        name="barName"
                        className="form-control"
                        placeholder="Bar Name"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group" style={{ marginBottom: "2.5rem" }}>
                      <input
                        type="text"
                        id="barAddress"
                        name="barAddress"
                        className="form-control"
                        placeholder="Bar Address"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group" style={{ marginBottom: "2.5rem" }}>
                      <input
                        type="text"
                        id="contactName"
                        name="contactName"
                        className="form-control"
                        placeholder="Contact Name"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group" style={{ marginBottom: "2.5rem" }}>
                      <input
                        type="email"
                        id="contactEmail"
                        name="contactEmail"
                        className="form-control"
                        placeholder="Contact Email"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group" style={{ marginBottom: "2.5rem" }}>
                      <input
                        type="text"
                        id="contactPhone"
                        name="contactPhone"
                        className="form-control"
                        placeholder="Contact Phone Number"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group" style={{ marginBottom: "2.5rem" }}>
                      <input
                        type="text"
                        id="preferredDate"
                        name="preferredDate"
                        className="form-control"
                        placeholder="Preferred Demo Date (MM/DD/YYYY)"
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group" style={{ marginBottom: "2.5rem" }}>
                      <textarea
                        name="notes"
                        id="notes"
                        className="form-control"
                        rows="4"
                        placeholder="Please include any other notes we should consider when preparing for our meeting."
                        required
                        onChange={handleChange}
                        style={{ width: "100%" }}
                      ></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                    <div id="success"></div>
                    <button type="submit" className="btn btn-custom btn-lg" style={{ display: "block", width: "100%", marginBottom: "10px" }}>
                      Send Message
                    </button>
                    <Link to="/" className="btn btn-custom btn-lg" style={{ display: "block", width: "100%" }}>
                      Back Home
                    </Link>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return content;
};
