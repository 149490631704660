import React, { useEffect, useRef, useState } from 'react';
import styles from './slideshow.module.css';

export const OSlideshow = (props) => {
  const observer = useRef(null);
  const [activeParagraphs, setActiveParagraphs] = useState([]);

  // Dummy data for demonstration
  const paragraphs = [
    { id: 'para1', header: "Paving the Way to More Efficient and Enjoyable Bar Service.", text: "In bustling bars with high order volumes, providing efficient and exceptional service is essential. Liquidity allows customers to place orders directly from their smartphones, freeing your bartenders to focus on crafting quality drinks. Our system eliminates the need to juggle fulfilling orders while taking new ones, preventing compromises in service speed and quality that can lead to customer dissatisfaction. Additionally, we notify customers when their drinks are ready, avoiding crowding at the bar. Our secure systems ensure that drinks are delivered to the right person.", image: 'img/outlet/outlets1.png' },
    { id: 'para2', header: "Seamless Integration With Your POS", text: "Seamlessly integrate Liquidity with your existing POS system without any hassle. Our platform is compatible with various POS systems commonly used in downtown Toronto, guaranteeing a smooth transition with minimal disruption to your operations. With synchronized data between Liquidity and your POS system, managing orders, payments, and inventory becomes efficient and centralized.", image: 'img/outlet/outlets2.png' },
    { id: 'para3', header: "Data-Driven Insights for Growth.", text: "Unlock powerful insights with Liquidity's robust analytics dashboard. Gain valuable knowledge about customer preferences, sales trends, and operational performance to drive strategic decisions and fuel growth. Whether you're optimizing your menu offerings, adjusting pricing strategies, or expanding your bar's footprint, our data-driven approach provides the actionable intelligence you need for success.", image: 'img/outlet/outlets4.png' },
    { id: 'para4', header: "Customer Engagement and Loyalty.", text: "Building customer loyalty is vital for long-term success in the competitive bar industry. Liquidity empowers you to engage deeply with your patrons through personalized promotions, loyalty programs, and targeted marketing campaigns. By rewarding repeat customers and incentivizing future visits, you can cultivate a loyal customer base that consistently returns.", image: 'img/outlet/outlets3.png' },
    // ... more paragraphs
  ];

  const { isMobile } = props;

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    };

    observer.current = new IntersectionObserver((entries) => {
      setActiveParagraphs(prevActiveParagraphs => {
        const activeSet = new Set(prevActiveParagraphs);
        entries.forEach(entry => {
          const id = entry.target.dataset.id;
          if (entry.isIntersecting) {
            activeSet.add(id);
          } else {
            activeSet.delete(id);
          }
        });
        return Array.from(activeSet);
      });
    }, options);

    const elements = document.querySelectorAll(`.${styles.paragraph}`);
    elements.forEach(element => {
      observer.current.observe(element);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  let content;
  if (isMobile) {
    content = (
      <div className={styles.slideshowMobile}>
        {paragraphs.map(({ id, image, header, text }) => (
          <div key={id}>
            <img
              className={styles.imgMobile}
              src={image}
              alt=""
            />
            <p className={styles.headerMobile}>{header}</p>
            <p className={styles.paragraphMobile}>{text}</p>
          </div>
        ))}
      </div>
    );
  } else {
    content = (
      <div className={styles.slideshowContainer}>
        <div className={styles.imageColumn} style={{ backgroundColor: '#FFF' }}>
          {paragraphs.map(({ id, image }) => (
            <img
              key={id}
              src={image}
              alt=""
              className={`${styles.fadeInOut} ${activeParagraphs.includes(id) ? styles.active : ''}`}
            />
          ))}
        </div>
        <div className={styles.textColumn} style={{ backgroundColor: '#FFF', color: "#FFFFFF" }}>
          {paragraphs.map(({ id, header, text }) => (
            <div key={id}>
              <p className={styles.header}>{header}</p>
              <p className={styles.paragraph} data-id={id}>
                {text}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div id="slideshow" className="text-center">
      <div className='feat-bg'>
        <div className="section-title">
          <h2>Our Features</h2>
        </div>
        {content}
      </div>
    </div>
  );
};
