import Deck from "./Deck";
import { Image } from "../image";
import React from "react";

export const OHeader1 = (props) => {
  return (
    <div id="portfolio" className="text-center">
    <div className="container">
      <div className="section-title">
        <h2>MOBILE ORDERING MADE EFFORTLESS
</h2>
<div>
    <p>
        {"At Liquidity, we understand the importance of seamless service for customers, and frictionless operations for establishments. That’s why we’ve created a platform that will pave the way to a new era of efficiency for bars everywhere."}
    </p>
</div>
      </div>
      <div className="container">
      <div className="row">
        <div className="portfolio-items">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-sm-6 col-md-4 col-lg-4"
                >
                  <Image
                    title={d.title}
                    largeImage={d.largeImage}
                    smallImage={d.smallImage}
                  />
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
      </div>
    </div>
  </div>
    
  );
};
