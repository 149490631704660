// Write your code here.
import FaqItem from '../faq-item/FaqItem'

import './index.css'

const Faqs = props => {
  const {faqsList, ofaqsList} = props

  return (
    <div id='faqpage'>
    <div className="app-container">
      <div className="faqs-container">
        <div className='faqhead'>
        <h1 className="heading">CUSTOMER FAQ's</h1>
        </div>
        <ul className="faqs-list">
          {faqsList.map(eachFaq => (
            <FaqItem key={eachFaq.id} faqDetails={eachFaq} />
          ))}
        </ul>
        <div className='faqhead'>
        <h1 className="heading">OUTLET FAQ's</h1>
        </div>
        <ul className="faqs-list">
          {ofaqsList.map(eachFaq => (
            <FaqItem key={eachFaq.id} faqDetails={eachFaq} />
          ))}
        </ul>
      </div>
    </div>
    </div>
  )
}

export default Faqs
