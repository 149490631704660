import React from "react";
import { Link } from "react-router-dom";
import CustomLink from '../helper/CustomLink';



export const Navigation = (props) => {
  return (
    <nav id="menu" style={{'position':'sticky', 'margin-bottom':'0rem'}} className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
        <Link to="/" className="navbar-brand page-scroll" href="#page-top">
            <img src="img/Liquidity_Icon_2.png" alt="Logo" className="logo"/>
            <span className="brand-text">Liquidity</span>
        </Link>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
            <CustomLink to="/" sectionId="header">Home</CustomLink>
            </li>
            {/* <li>
            <CustomLink to="/" sectionId="about">About</CustomLink>
            </li>
            <li>
            <CustomLink to="/" sectionId="services">Services</CustomLink>

            </li> */}
            <li>
            <CustomLink to="/forbars" sectionId="header">Liquidity For Bars</CustomLink>

            </li>
            <li>
            <CustomLink to="/faq" sectionId="faqpage">FAQ</CustomLink>

            </li>
            <li>
            <CustomLink to="/" sectionId="contact">Contact</CustomLink>

            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};